import { graphql } from 'gatsby';
import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../components/Layout';
import AboutPageTemplate from '../components/AboutPage';
import { HTMLContent } from '../components/Content';

function AboutPage({ data }) {
  const {
    html,
    frontmatter: {
      title,
    },
  } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={title}
        content={html}
      />
    </Layout>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;

export default AboutPage;
